@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url("https://fonts.googleapis.com/css?family=Atkinson+Hyperlegible:600|Poppins:600|Lexend:600|Raleway:600|Comic+Neue|Noto+Sans:600|Cousine|Inconsolata&display=swap");

/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
body {
  box-sizing: border-box;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  app-root {
    display: flex;
    flex: 0 1 100%;
    justify-content: stretch;
  }
}

.btn.btn-circle, .btn.btn-lg {
  ng-icon {
    @apply text-2xl;
  }
  &.btn-xs {
    ng-icon {
      @apply text-sm;
    }
  }
}

.btn.btn-half-circle-left {
  height: 3rem;
  width: 2rem;
  border-radius: 50% 0 0 50%;
}

.no-height {
  height: 0;
  margin-top: 3px;
}

.recognized-text {
  @apply break-words;
  &.textSize-xs {
    @apply text-xs;
    &.live {
      @apply text-base;
    }
  }
  &.textSize-sm {
    @apply text-xs sm:text-sm;
    &.live {
      @apply text-base;
    }
  }
  &.textSize-base {
    @apply text-sm sm:text-base;
    &.live {
      @apply text-lg;
    }
  }
  &.textSize-lg {
    @apply text-lg;
    &.live {
      @apply text-xl;
    }
  }
  &.textSize-xl {
    @apply text-xl;
    &.live {
      @apply text-2xl;
    }
  }
  &.textSize-2xl {
    @apply text-2xl;
    &.live {
      @apply text-3xl;
    }
  }
  &.textSize-3xl {
    @apply text-3xl;
    &.live {
      @apply text-4xl;
    }
  }
  &.textSize-4xl {
    @apply text-4xl;
    &.live {
      @apply text-5xl;
    }
  }
  &.textSize-5xl {
    @apply text-5xl;
    &.live {
      @apply text-6xl;
    }
  }
  &.textSize-6xl {
    @apply text-5xl sm:text-6xl;
    &.live {
      @apply text-6xl sm:text-7xl;
    }
  }
  &.textSize-7xl {
    @apply text-5xl sm:text-7xl;
    &.live {
      @apply text-6xl sm:text-8xl;
    }
  }
  &.textSize-8xl {
    @apply text-5xl sm:text-8xl;
    &.live {
      @apply text-9xl;
    }
  }
  &.textSize-9xl {
    @apply text-5xl sm:text-9xl;
  }
  &.lineHeight-none {
    @apply leading-none;
    &.live {
      @apply leading-none;
    }
  }
  
  &.lineHeight-tight {
    @apply leading-tight;
    &.live {
      @apply leading-tight;
    }
  }
  
  &.lineHeight-snug {
    @apply leading-snug;
    &.live {
      @apply leading-snug;
    }
  }
  
  &.lineHeight-normal {
    @apply leading-normal;
    &.live {
      @apply leading-normal;
    }
  }
  
  &.lineHeight-relaxed {
    @apply leading-relaxed;
    &.live {
      @apply leading-relaxed;
    }
  }
  
  &.lineHeight-loose {
    @apply leading-loose;
    &.live {
      @apply leading-loose;
    }
  }
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}
}

.pulse-once {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	transform: scale(1);
  animation: pulse 1250ms ease-in;
}